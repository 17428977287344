import React, { memo, useRef } from "react";
import { Box, Button, CircularProgress,  Theme, Typography, useMediaQuery } from "@mui/material";
import DocViewer, { PDFRenderer } from "@cyntler/react-doc-viewer"
import "@cyntler/react-doc-viewer/dist/index.css"
import "../../components/PdfViewer/pdfFile.css";
import IconComponent from "../Icons";
import { aosLogFileAccessTime, decodePDF } from "../../services/api/subject";
import { getToken } from "../../services/api/login";
import { formatDate } from "../../utils/utilfns";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface FilesPreviewProps {
  fileUrl: string;
  fileId: number;
  password: string;
  isFullContainer: boolean;
}

const FilesPreview: React.FC<FilesPreviewProps> = memo(
  ({ fileUrl, fileId, password, isFullContainer }) => {
    const pdfAbortControllerRef = useRef<AbortController | null>(null);
    const [docs, setDocs] = React.useState<any>([{ uri: "" }]);
    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    );
    const docViewerRef = useRef<any>(null);
    const pdfRef = useRef<any>(null);
    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const [pdfLoading, setpdfLoading] = React.useState(false);
    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [pdfLoadingError, setpdfLoadingError] = React.useState<null|string>(null);

    const handleFileLogTime = async (
      file_id: any,
      startTime: any,
      endTime: any
    ) => {
      const verifyAccess = getToken();
      try {
        const payload = {
          file_id: file_id,
          starttime: startTime,
          endtime: endTime,
        };
        const response = await aosLogFileAccessTime(
          payload,
          verifyAccess.token
        );
        console.log("file log time", response?.data.data);
      } catch (error) {
        console.error("Error fetching file type details:", error);
      }
    };

    const loadPDF = () => {
      setpdfLoadingError(null);
      setDocs([{ uri: "" }]);
      setpdfLoading(true);
      const verifyAccess = getToken();
    
      // Create a new AbortController for each request
      const controller = new AbortController();
      const { signal } = controller;
    
      // Save the controller reference to cancel on unmount or URL change
      pdfAbortControllerRef.current?.abort(); // Cancel previous request if exists
      pdfAbortControllerRef.current = controller;
    
      decodePDF(verifyAccess.token, fileId, password, (progressEvent) => {
        if (progressEvent.total) {
          const percent = Math.round((100 * progressEvent.loaded) / progressEvent.total);
          setLoadingProgress(percent);
          console.log(`Progress: ${percent}%`);
        }
      }, signal)
        .then((res) => {
          if (res?.status === 200) {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" });
            const url = URL.createObjectURL(pdfBlob);
            setDocs([{ uri: url }]);
            setpdfLoading(false);
            setpdfLoadingError(null);
            setLoadingProgress(0);
          } else {
            throw new Error("Invalid response from server");
          }
        })
        .catch((error) => {
          if (error.name === "AbortError"|| error.name === "CanceledError") {
            console.log("PDF Load Aborted");
          } else {
            
            console.error("PDF Load Error:", error);
            handlePDFLoadError(error);
          }
        });
    };

    const handlePDFLoadError = (error: any) => {
      if (!navigator.onLine) {
        setpdfLoadingError("You are offline. Please check your internet connection.");
        setpdfLoading(false);
        setLoadingProgress(0);
      } else if (error?.response) {
        switch (error.response.status) {
          case 404:
            setpdfLoadingError("File not found or no access.");
            setpdfLoading(false);
            setLoadingProgress(0);
            break;
          case 401:
            setpdfLoadingError("Unauthorized access. Please login again.");
            setpdfLoading(false);
            setLoadingProgress(0);
            break;
          case 500:
            setpdfLoadingError("Server error. Please try again later.");
            setpdfLoading(false);
            setLoadingProgress(0);
            break;
          default:
            setpdfLoadingError("Failed to load PDF. Please try again.");
            setpdfLoading(false);
            setLoadingProgress(0);
        }
      } else if (error?.message?.includes("Network Error")) {
        setpdfLoadingError("Network error. Server might be down.");
        setpdfLoading(false);
        setLoadingProgress(0);
      } else {
        setpdfLoadingError("An unknown error occurred.");
        setpdfLoading(false);
        setLoadingProgress(0);
      }
    };

    React.useEffect(() => {
      loadPDF();
      return () => {
        pdfAbortControllerRef.current?.abort(); // Cancel API call on unmount or fileUrl change
        setDocs([{ uri: "" }]);
        setLoadingProgress(0);
        setpdfLoading(false);
        setpdfLoadingError(null);
      };
    }, [fileUrl]);

    const reloadPDF = loadPDF;

    React.useEffect(() => {
      const currentTime = formatDate();
      return () => {
        const endtime = formatDate();
        handleFileLogTime(fileId, currentTime, endtime);
      };
    }, [fileUrl]);
    React.useEffect(() => {
      console.log("pdfLoading",pdfLoading)
    },[pdfLoading])

    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };

    React.useEffect(() => {
      document.addEventListener("fullscreenchange", handleFullscreenChange);
      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange
        );
      };
    }, []);

    // const handleFullScreenMode = () => {
    //   if (docViewerRef.current) {
    //     if (!isFullscreen) {
    //       docViewerRef.current.requestFullscreen().catch((err) => {
    //         console.error("Error attempting to enable fullscreen mode:", err);
    //       });
    //     } else {
    //       document.exitFullscreen().catch((err) => {
    //         console.error("Error attempting to exit fullscreen mode:", err);
    //       });
    //     }
    //   }
    // };
    const handleFullScreenMode = () => {
      if (docViewerRef.current) {
        const element = docViewerRef.current;

        if (element.requestFullscreen) {
          // Standard Fullscreen API
          element.requestFullscreen().catch((err: any) => {
            console.error("Error attempting to enable fullscreen mode:", err);
          });
        } else if (element.webkitRequestFullscreen) {
          // Safari-specific API
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          // Older IE/Edge API
          element.msRequestFullscreen();
        } else {
          // Fallback: Simulate fullscreen with CSS
          element.style.position = "fixed";
          element.style.top = "0";
          element.style.left = "0";
          element.style.width = "100vw";
          element.style.height = "100vh";
          element.style.zIndex = "9999";
          setIsFullscreen(true);
        }
      }
    };

    const exitFullScreenMode = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        // Safari-specific API
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        // Older IE/Edge API
        (document as any).msExitFullscreen();
      } else {
        // Fallback: Reset styles
        if (docViewerRef.current) {
          const element = docViewerRef.current;
          element.style.position = "";
          element.style.top = "";
          element.style.left = "";
          element.style.width = "";
          element.style.height = "";
          element.style.zIndex = "";
        }
        setIsFullscreen(false);
      }
    };

    const MyLoadingRenderer = () =>
      pdfLoading ? <div>Loading ...</div> : <div>Loading Renderer...</div>;

    const MyNoRenderer = ({ document, fileName }: any) => {
      const fileText = fileName || document?.fileType || "";
      return document.uri !== "" ? (
        <div>No Renderer Error! ({fileText})</div>
      ) : (
        <></>
      );
    };

    return (
      <Box
        className={"pdfMain"}
        sx={{ position: "relative" }}
        ref={docViewerRef}
      >
        {pdfLoading ? (
           <Box
           width={"100%"}
           height={"calc(100vh - 176px)"}
           sx={{
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
           }}
         >
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              width: 120,
              height: 120,
              borderRadius: "50%",
              backgroundColor: "rgba(26, 144, 255, 0.1)",
              boxShadow: "0px 0px 44px #c8c8c8,-39px -39px 0px #ffffff",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* Circular Progress */}
            <CircularProgress
              variant="determinate"
              value={loadingProgress}
              size={100}
              thickness={5}
              sx={{
                color: "#FFC071",
                position: "absolute",
              }}
            />
            {/* Inner Percentage Text */}
            <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                textAlign:"center",
                color: "#333",
                textShadow: "1px 1px 3px rgba(0,0,0,0.1)",
              }}
            >
              {`${Math.round(loadingProgress)}%`}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 500,
                fontSize: "8px",
                color: "#333",
                textShadow: "1px 1px 3px rgba(0,0,0,0.1)",
              }}
            >
              Downloading PDF...
            </Typography>
            </Box>
          </Box>
          </Box>
        ) : pdfLoadingError!==null ? (
          <Box
          width={"100%"}
          height={isMobile ? "calc(100vh - 100px)" : "calc(100vh - 176px)"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography variant="caption">{pdfLoadingError}</Typography>
            <Button variant="outlined" onClick={() => reloadPDF()}>Retry</Button>
        </Box>
        ) : (
          <DocViewer
            pluginRenderers={[PDFRenderer]}
            ref={pdfRef}
            documents={docs}
            config={{
              pdfVerticalScrollByDefault: true,
              loadingRenderer: { overrideComponent: MyLoadingRenderer },
              noRenderer: { overrideComponent: MyNoRenderer },
              header: {
                disableHeader: true,
                disableFileName: false,
                retainURLParams: false,
              },
              pdfZoom: {
                defaultZoom: isMobile ? 1.3 : isFullContainer ? 1 : 0.7,
                zoomJump: 0.1,
              },
            }}
            style={{
              height: isFullscreen
                ? "100vh"
                : isMobile
                  ? "calc(100vh - 300px)"
                  : "calc(100vh - 200px)",
              backgroundColor: "#2B3258",
              overflowY: "scroll",
              borderRadius: isFullscreen ? "0px" : "16px",
            }}
          />
        )}
        {!pdfLoading && (
          <Box
            sx={{
              position: "absolute",
              top: { md: "8px", sm: "6px", xs: "6px" },
              right: { md: "30px", sm: "20px", xs: "20px" },
              padding: "4px",
              display: "flex",
              border: "1px solid transparent",
              zIndex: "10",
              ":hover": {
                border: "1px solid #D2D2D0",
                background: "#FFF8EC1A",
                borderRadius: "8px",
              },
            }}
            onClick={() => {
              isFullscreen ? exitFullScreenMode() : handleFullScreenMode();
            }}
          >
            <IconComponent
              name="expandVideoFull"
              fill={"#FFFFFF"}
              width={20}
              height={20}
            />
          </Box>
        )}
      </Box>
    );
  }
);

export default withErrorBoundary(
  FilesPreview,
  <>Something went wrong with FilesPreview Component</>
);

