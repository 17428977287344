import { Box, Typography, styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import IconComponent from "../../components/Icons";
import "../../index.css";
import React from "react";
import Button from "../Button";
import { secondsToDuration } from "../../utils/utilfns";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface RelatedAosResourseProps {
  title: string;
  subtitle: string;
  type: "video" | "pdf";
  lastOpened: string;
  buttonText: string;
  imageUrl?: string;
  file_id: any;
  duration: any;
  file_type_id: any;
  subject_id: any;
  topic_id: any;
  area_of_study_id: any;
  ext_type: any;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: "-4px",
  height: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const RelatedAosResourse: React.FC<RelatedAosResourseProps> = ({
  title,
  subtitle,
  type,
  lastOpened,
  buttonText,
  imageUrl,
  file_id,
  duration,
  file_type_id,
  area_of_study_id,
  subject_id,
  ext_type,
  topic_id
}) => {
  const navigate=useNavigate()
  const handleOnResourceClick=(items:any)=>{
    const newSearch = new URLSearchParams();
    newSearch.set('sid', items.subject_id);
    newSearch.set('aosID', items.area_of_study_id);
    newSearch.set('topicID', items.topic_id);
    newSearch.set('typeID', items.file_type_id);
    newSearch.set('fileID', items.file_id);
    navigate(`/aos?${newSearch.toString()}`);
  }

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          paddingX: "8px",
          width: "246px",
        }}
      >
        <Box
          className="hoverDiv"
          sx={{
            borderRadius: "12px",
            marginBottom: "12px",
            width: "100%",
            border: "1px solid #D2D2D0",
            cursor: "pointer"
          }}
          onClick={ () => 
            handleOnResourceClick({
              subject_id:subject_id,
              area_of_study_id:area_of_study_id,
              topic_id:topic_id,
              file_type_id:file_type_id,
              file_id:file_id
            })}
        >
          {ext_type === "pdf" && (
            <Box sx={{ position: "relative" }}>
              <Box
                className="hoverOpacity"
                sx={{
                  paddingTop: "13px",
                  paddingX: "21px",
                  background: "#F4F6FF",
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{ width: "100%", height: "116px" }}
                  component="img"
                  src={
                    imageUrl
                      ? `${process.env.REACT_APP_CDN}/${imageUrl}`
                      : ""
                  }
                  alt="LoginSmily"
                  crossOrigin="anonymous"
                />
              </Box>
              <Box
                className="hoverShow"
                sx={{
                  display: "none",
                  maxWidth: "168px",
                  position: "absolute",
                  bottom: "50%",
                  width: "100%",
                  left: "50%",
                  transform: "translate(-50%, 50%)",
                }}
              >
                <Button
                  text={buttonText}
                  textStyle={{ color: "#333" }}
                  handleClick={() => console.log("Open workbook1")}
                />
              </Box>
            </Box>
          )}
          {ext_type === "video" && (
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  background: "#F4F6FF",
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "130px",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                  component="img"
                  crossOrigin="anonymous"
                  src={
                    imageUrl
                      ? `${process.env.REACT_APP_CDN}/${imageUrl}`
                      : ""
                  }
                  alt="LoginSmily"
                  
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "50%",
                    left: "50%",
                    transform: "translate(-50%, 50%)",
                  }}
                  component={"div"}
                >
                  <>
                  <IconComponent name="videControl" fill={"#8E8D8A"} />
                  </>
                </Box>
              </Box>
              <Box
                className="hoverVideControl"
                sx={{
                  display: "none",
                  position: "absolute",
                  bottom: "50%",
                  left: "50%",
                  transform: "translate(-50%, 50%)",
                  cursor: "pointer",
                }}
                component={"div"}
              >
                <IconComponent name="videControl" fill={"#8E8D8A"} />
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "15px",
                  color: "#FFF",
                  position: "absolute",
                  right: "8px",
                  top: "8px",
                  background: "#2B3258",
                  paddingX: "4px",
                  borderRadius: "8px",
                }}
              >
                {secondsToDuration(duration)}
              </Typography>
              <BorderLinearProgress variant="determinate" value={50} />
            </Box>
          )}
          <Box sx={{ padding: "16px",cursor:"pointer" }} >
            <Typography
              sx={{
                paddingX: "8px",
                paddingY: "4px",
                background: "#FFF0E2",
                borderRadius: "4px",
              }}
              variant="detail"
              color={"#333333"}
              fontWeight={500}
              lineHeight={"15px"}
            >

              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "18.2px",
                marginTop: "8px",
                color: "#000000",
                maxHeight: '38px',
                minHeight: '38px',
                overflow: 'hidden',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                textOverflow: 'ellipsis',
                display: "-webkit-box",
              }}
            >
              {subtitle}
            </Typography>
            <Typography
              sx={{
                display: "block",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "18.2px",
                marginTop: "4px",
                color: "#333333",
              }}
            >
              {type}
            </Typography>
           <Typography
              sx={{
                display: "block",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18.2px",
                marginTop: "4px",
                color: "#696969",
                height:"18px"
              }}
            >
            {lastOpened!==null ?`Last opened: ${moment(moment.utc(lastOpened).toDate()).local().fromNow()}`:""}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withErrorBoundary(RelatedAosResourse,<>Something went wrong with RelatedAosResourse Component</>);
