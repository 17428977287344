import client from "./client";
import apiClientFileUpload from "./apiClientFileUpload";

interface subjectViewPayload {
  class_id?: string;
  subject_id: string;
  aos_id?: string;
  type_id?: string;
  topic_id?: string;
  file_id?: string;
  file_type?: string;
}
interface globalPayload {
  page?: any;
  limit?: any;
  FileType: string[];
  Subject: string[];
  Search: string;
}
interface aosLogFileAccessTimePayload {
  file_id: string;
  starttime: string;
  endtime: string;
}

export const getSubjectDetails = async (
  payload: subjectViewPayload,
  token: string
) =>
  await client.get(
    `/student-subjects/view-subjects${
      payload?.subject_id ? `?subjectFilter=${payload?.subject_id}` : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getSubjectAOSDetailsByID = async (
  payload: subjectViewPayload,
  token: string
) => {
  if (payload?.subject_id) {
    return await client.get(
      `/student-aos/view-aos-filter${
        payload?.subject_id
          ? `?subjectFilter=${payload?.subject_id}${
              payload?.aos_id ? `&aos_id=${payload?.aos_id}` : ""
            }`
          : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    console.error("subject ID is mandatory");
  }
};

export const getAllStudentSelectedSubjects = async (token: string) =>
  await client.get("/student-aos/view-aos-sub", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getFileTypesByAOSID = async (
  payload: subjectViewPayload,
  token: string
) => {
  if (payload?.subject_id) {
    return await client.get(
      `/student-aos/view-aos-filetype${
        payload?.subject_id
          ? `?subjectFilter=${payload?.subject_id}${
              payload?.aos_id ? `&aos_id=${payload?.aos_id}` : ""
            }`
          : ""
      }${payload?.topic_id ? `&topic_id=${payload?.topic_id}` : ""}${
        payload?.type_id ? `&type_id=${payload?.type_id}` : ""
      }${payload?.file_id ? `&file_id=${payload?.file_id}` : ""}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    console.error("subject ID is mandatory");
  }
};
export const globalSearch = async (payload: globalPayload, token: string) => {
  if (payload?.Search) {
    try {
      // Convert Filetype and Subject to comma-separated strings if they are arrays
      const filetypeParam = Array.isArray(payload.FileType)
        ? payload.FileType.join(",")
        : payload.FileType || "";
      const subjectParam = Array.isArray(payload.Subject)
        ? payload.Subject.join(",")
        : payload.Subject || "";

      const response = await client.get("/global-search/search", {
        params: {
          FileType: filetypeParam,
          Subject: subjectParam,
          Search: payload.Search,
          page: payload.page,
          limit: payload.limit,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error);
      throw error;
    }
  } else {
    console.error("Search parameter is mandatory");
    throw new Error("Search parameter is mandatory"); // Throw an error to indicate mandatory parameter missing
  }
};

export const getAOSRecording = async (
  payload: subjectViewPayload,
  token: string
) => {
  if (payload?.subject_id) {
    return await client.get(
      `/student-aos/view-aos-rec${
        payload?.subject_id
          ? `?subjectFilter=${payload?.subject_id}${
              payload?.aos_id ? `&aos_id=${payload?.aos_id}` : ""
            }${payload?.file_type ? `&file_type=${payload?.file_type}` : ""}`
          : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    console.error("subject ID is mandatory");
  }
};

export const getAOSRelatedResource = async (
  payload: subjectViewPayload,
  token: string
) => {
  if (payload?.subject_id) {
    return await client.get(
      `/student-aos/related_resources${
        payload?.subject_id
          ? `?subject_id=${payload?.subject_id}${
              payload?.aos_id ? `&aos_id=${payload?.aos_id}` : ""
            }${payload?.file_type ? `&file_type=${payload?.file_type}` : ""}`
          : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    console.error("subject ID is mandatory");
  }
};

export const getChapters = async (token: string, params?: any) =>
  await client.get(`/files/getChapters/${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  export const decodePDF = async (
    token: string,
    file_id: number,
    pass: string,
    onDownloadProgress?: (progressEvent: any) => void,
    signal?: AbortSignal // Accept an optional AbortSignal
  ) => {
    try {
      const response = await client.get(`/files/decode-file/${file_id}/${pass}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "arraybuffer",
        onDownloadProgress: (event) => {
          if (onDownloadProgress) onDownloadProgress(event);
        },
        signal, // Pass the AbortSignal to axios
      });
  
      if (!response || response.status !== 200) {
        throw new Error("Invalid response from API");
      }
  
      return response;
    } catch (error: any) {
      if (error.name === "CanceledError") {
        console.log("decodePDF request was aborted.");
      } else {
        console.error("decodePDF Error:", error);
      }
      throw error; // Ensure the calling function can handle errors
    }
  };

export const getRecentViewedDetails = async (token: string) =>
  await client.get("/student/view-files", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const aosLogFileAccessTime = async (
  payload: aosLogFileAccessTimePayload,
  token: string
) =>
  await client.post("/areaofstudy/log-file-accesstime", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const videoLogFileSeekTime = async (
  payload: {
    file_id: string;
    time: string;
    currenttime: string;
    endtime: string;
  },
  token: string
) =>
  await client.post(
    `/areaofstudy/timespent/${payload.file_id}`,
    {
      timespent: payload.time,
      starttime: payload.currenttime,
      endtime: payload.endtime,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const markAsComplete = async (payload: object, token: string) =>
  await client.post("/student-aos/mark-as-complete", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateSubjectAccessTime = async (payload: object, token: string) =>
  await client.post("students/last-access-date", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const convertOptimizedImage = (imageURL: any) => {
  if (!imageURL) {
    return "";
  }
  return `${process.env.REACT_APP_PUBLIC_IMAGE_OPTIMIZATION_URL}${imageURL}`;
};

export const convertOptimizedFile = (imageURL: any) => {
  if (!imageURL) {
    return "";
  }
  return `${process.env.REACT_APP_PUBLIC_PDF_OPTIMIZATION_URL}${imageURL}`;
};
export const getCourseRecording = async (
  payload: subjectViewPayload,
  token: string
) => {
  try {
    const response = await apiClientFileUpload.get(
      `/class/view-cls-rec-list?subjectFilter=${payload.subject_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching course recording:", error);
    throw error;
  }
};
export const getCourseRecordingClassID = async (
  payload: subjectViewPayload,
  token: string
) => {
  try {
    const response = await apiClientFileUpload.get(
      `/class/view-class-recording-filter?subjectFilter=${payload?.subject_id}&class_id=${payload?.class_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching course recording:", error);
    throw error;
  }
};
