import {
  Box,
  Grid,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import IconComponent from "../../components/Icons";
import Button from "../../components/Button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getSubjectFormat, secondsToDuration } from "../../utils/utilfns";
import NoImageFound from "../../assets/images/NoImageFound2.png"
import withErrorBoundary from "../../utils/withErrorBoundary";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: "-4px",
  height: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const SearchList = ({ filter }: { filter: any }) => {
  const navigate = useNavigate();
  const handleContent = (subject: any) => {
    console.log("searchdata",subject)
    const newSearch = new URLSearchParams();
    newSearch.set('sid', subject.subject_id);
    newSearch.set('aosID', subject.area_of_study_id);
    newSearch.set('topicID', subject.topic_id);
    newSearch.set('typeID', subject.type_id);
    newSearch.set('fileID', subject.id);
    navigate(`/aos?${newSearch.toString()}`);
  };
  return (
    <Box sx={{}}>
      <Grid
        container
        sx={{
          width: "100%",
          maxHeight: { md: "376px", sm: "376px", xs: "calc(100vh - 190px)" },
          overflowY: "auto",
        }}
      >
        {filter.map((item: any, index: any) => (
          <Grid
            key={index}
            item
            md={12}
            marginTop={{ md: "0px", sm: "16px", xs: "8px" }}
            display={{ md: "block", sm: "block", xs: "block" }}
          >
            <Box
              sx={{
                paddingX: { md: "0px", sm: "0px", xs: "0px" },
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  borderRadius: { md: "12px", sm: "12px", xs: "0px" },
                  marginBottom: { md: "20px", sm: "20px", xs: "8px" },
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  border: "0px",
                }}
                onClick={() => handleContent(item)}
              >

                {item.ext_type === "pdf" && (
                  <Box sx={{ position: "relative", maxWidth: "156px" }}>
                    <Box
                      className="hoverOpacity"
                      sx={{
                        paddingTop: "13px",
                        paddingX: "5px",
                        background: "#F4F6FF",
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        position: "relative",
                      }}
                      component={"div"}
                      onClick={() => console.log("Open workbook1")}
                    >
                      <Box
                        sx={{
                          borderTopLeftRadius: "0",
                          borderTopRightRadius: "0",
                          width: "156px",
                          height: "78px",
                          objectFit: "cover",
                          objectPosition: "center"
                        }}
                        component="img"
                        crossOrigin="anonymous"
                        src={`${process.env.REACT_APP_CDN}/${item.thumbnail_url}`}
                        alt="LoginSmily"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.onerror = null; // Prevents infinite loop if fallback image fails
                          target.src = NoImageFound;
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { md: "block ", sm: "block", xs: "none" },
                      }}
                    >
                      <Box
                        className="hoverShow"
                        sx={{
                          display: "none",
                          maxWidth: "168px",
                          position: "absolute",
                          bottom: "50%",
                          width: "100%",
                          left: "50%",
                          transform: "translate(-50%, 50%)",
                        }}
                      >
                        <Button
                          text={item.buttonText}
                          textStyle={{ color: "#333" }}
                          handleClick={() => console.log("Open workbook1")}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {item.ext_type === "video" && (
                  <Box sx={{ position: "relative", maxWidth: "156px" }}>
                    <Box
                      sx={{
                        background: "#F4F6FF",
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        position: "relative",
                      }}
                      component={"div"}
                      onClick={() => console.log("Open workbook1")}
                    >
                      <Box
                        sx={{
                          borderTopLeftRadius: "0",
                          borderTopRightRadius: "0",
                          width: "156px",
                          objectFit: "cover",
                          objectPosition: "center",
                          height: "78px"
                        }}
                        component="img"
                        crossOrigin="anonymous"
                        src={`${process.env.REACT_APP_CDN}/${item.thumbnail_url}`}
                        alt="LoginSmily"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.onerror = null; // Prevents infinite loop if fallback image fails
                          target.src = NoImageFound;
                        }}
                      />
                      <Box component={"img"} crossOrigin="anonymous" />
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "50%",
                          left: "50%",
                          transform: "translate(-50%, 50%)",
                        }}
                        component={"div"}
                        onClick={() => console.log("Video")}
                      >
                        <IconComponent name="videControl" fill={"#8E8D8A"} />
                      </Box>
                    </Box>
                    <Box
                      className="hoverVideControl"
                      sx={{
                        display: "none",
                        position: "absolute",
                        bottom: "50%",
                        left: "50%",
                        transform: "translate(-50%, 50%)",
                        cursor: "pointer",
                      }}
                      component={"div"}
                      onClick={() => console.log("Video")}
                    >
                      <IconComponent name="videControl" fill={"#8E8D8A"} />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "15px",
                        color: "#FFF",
                        position: "absolute",
                        right: "8px",
                        top: "8px",
                        background: "#2B3258",
                        paddingX: "4px",
                        borderRadius: "8px",
                      }}
                    >
                      {secondsToDuration(item.duration)}
                    </Typography>
                    <BorderLinearProgress variant="determinate" value={50} />
                  </Box>
                )}
                <Box sx={{ paddingX: "12px", paddingY: "8.5px" }}>
                  <Typography
                    sx={{
                      paddingX: "8px",
                      paddingY: "4px",
                      background: "#FFF0E2",
                      borderRadius: "4px",
                      "@media screen and (max-width:374px)": {
                        fontSize: "8px",
                      },
                    }}
                    variant="detail"
                    color={"#333333"}
                    fontWeight={500}
                    lineHeight={"15px"}
                  >
                    {getSubjectFormat(item.subject)}
                  </Typography>
                  <Typography
                    sx={{
                      // display: "block",
                      display: "-webkit-box",
                      fontSize: { md: "12px", sm: "12px", xs: "12px" },
                      fontWeight: "600",
                      lineHeight: "18.2px",
                      marginTop: "8px",
                      color: "#000000",
                      maxHeight: '38px',
                      minHeight: '38px',
                      overflow: 'hidden',
                      '-webkit-line-clamp': '2',
                      '-webkit-box-orient': 'vertical',
                      textOverflow: 'ellipsis',
                      width:"180px",
                      "@media screen and (max-width:374px)": {
                        fontSize: "8px",
                        width: "100px",
                      },
                    }}
                  >
                    {item.file_name}
                  </Typography>
                  <Typography
                    sx={{
                      display: "block",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "18.2px",
                      marginTop: "4px",
                      color: "#696969",
                      height:"18px",
                      "@media screen and (max-width:374px)": {
                        fontSize: "8px",
                      },
                    }}
                  >
                    {item.last_opened!==null?`Last opened: ${moment(moment.utc(item.last_opened).toDate()).local().fromNow()}`:""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default withErrorBoundary(SearchList,<>Something went wrong with SearchList Component</>);
