import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import Icons from "../Icons";
import Button from "../Button";
import BugReportDetails from "../BugReport";
import { reportBug } from "../../services/api/dashboard";
import { getToken } from "../../services/api/login";

type ToastState = {
  type: string;
  message: string;
};

interface bugleftPopupProps {
  open: boolean;
  handleClose: () => void;
  cancel: () => void;
  bugTitle?: string;
  internetSpeed?: string | null;
  browserInfo?: string;
  bugDescription?: string;
  attachment?: (File | string)[];
  handleBugTitleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBugDescriptionChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  captureScreenshot?: () => void;
  setAttachment?: React.Dispatch<React.SetStateAction<(File | string)[]>>;
  setToast?: React.Dispatch<React.SetStateAction<ToastState | null>>;
  bugRepId?: string;
  selectedSubModule?: {
    id: string;
    module: string;
  };
  selectedType?: string | undefined;
  titleError: boolean;
  descriptionError: boolean;
}

export default function BugleftPopup({
  open,
  bugRepId,
  handleClose,
  cancel,
  bugTitle,
  bugDescription,
  attachment,
  handleBugTitleChange,
  handleBugDescriptionChange,
  captureScreenshot,
  setAttachment,
  setToast,
  selectedSubModule,
  selectedType,
  titleError,
  descriptionError,
}: bugleftPopupProps) {
  const verifyAccess = getToken();
  const isDisabled = bugTitle === "" || bugDescription === "" || titleError || descriptionError;
  const handleBugSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("browserInfo", JSON.stringify(browserInfo) || "");
      formData.append("speed", internetSpeed || "");
      formData.append("bug_title", bugTitle || "");
      formData.append("bug_description", bugDescription || "");
      formData.append("file_id", bugRepId || "");

      attachment &&
        attachment.forEach((file) => {
          if (file instanceof File) {
            formData.append("attachment", file, file.name);
          }
        });

      const token = verifyAccess.token;
      const response = await reportBug(formData, token);
      if (response && response.data) {
        const successMessage = response.data.message;
        setToast &&
          setToast({
            type: "success",
            message: successMessage,
          });
        cancel();
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast &&
          setToast({
            type: "error",
            message: errorMessage,
          });
      }
    } catch (error) {
      console.error("Error submitting bug report:", error);
    }
  };
    const [internetSpeed, setInternetSpeed] = React.useState<string | null>(null);
    const getInternetSpeed = async () => {
      const fileUrl = "public/1mb.pdf"; // A small test file (1MB)
      const fileSizeInBits = 1 * 8 * 1024 * 1024; // 1MB in bits
    
      const startTime = performance.now();
    
      try {
        const response = await fetch(fileUrl, { method: "GET", cache: "no-store" });
        await response.blob(); // Ensure full download
    
        const endTime = performance.now();
        const durationInSeconds = (endTime - startTime) / 1000;
        
        const speedMbps = fileSizeInBits / (durationInSeconds * 1_000_000); // Speed in Mbps
        const speedMBps = (speedMbps / 8).toFixed(2); // Convert to MB/s
    
        setInternetSpeed(`${speedMBps} Mbps`); // Display speed in MB/s
      } catch (error) {
        setInternetSpeed("Unable to detect speed");
      }
    };
    
      // Fetch the internet speed when the component mounts
      React.useEffect(() => {
        getInternetSpeed();
      }, []);


        const [browserInfo, setBrowserInfo] = React.useState({
                    userAgent: navigator.userAgent,
                    fullVersion: "Unknown"
                });
            
                React.useEffect(() => {
                  async function getBrowserVersion() {
                    try {
                      const nav = navigator as any;
              
                      if (nav.userAgentData?.getHighEntropyValues) {
                        // ✅ For Chromium browsers (Chrome, Edge, Opera)
                        const highEntropyValues = await nav.userAgentData.getHighEntropyValues(["uaFullVersion"]);
                        setBrowserInfo({
                          userAgent: navigator.userAgent,
                          fullVersion: highEntropyValues.uaFullVersion,
                        });
                      } else {
                        let fullVersion = "Unknown";
              
                        if (navigator.userAgent.includes("Firefox")) {
                          // ✅ Improved regex to capture full Firefox version
                          const match = navigator.userAgent.match(/Firefox\/([\d.]+)/);
                          fullVersion = match ? match[1] : "Unknown";
                        } else if (navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome")) {
                          // ✅ Safari version detection (avoids picking up Chrome-based Safari)
                          const safariMatch = navigator.userAgent.match(/Version\/([\d.]+)/);
                          fullVersion = safariMatch ? safariMatch[1] : "Unknown";
                        } else {
                          // ✅ General fallback for other browsers
                          const match = navigator.userAgent.match(/(firefox|chrome|safari|edge|opr|version)[/\s]?([\d.]+)/i);
                          fullVersion = match ? match[2] : "Unknown";
                        }
              
                        setBrowserInfo({
                          userAgent: navigator.userAgent,
                          fullVersion,
                        });
                      }
                    } catch (error) {
                      console.error("Error fetching browser version:", error);
                      setBrowserInfo((prev) => ({ ...prev, fullVersion: "Error fetching version" }));
                    }
                  }
              
                getBrowserVersion();
                }, []);
                console.log(browserInfo.fullVersion);
      
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          borderRadius: "16px",
          maxWidth: "560px",
          ".MuiBackdrop-root": {
            background: "transparent",
          },
          ".MuiPaper-rounded": {
            borderRadius: "16px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            paddingX: "28px",
            paddingY: "16px",
            borderBottom: "1px solid #EFEFEF",
          }}
        >
          <Typography
            color={"#333"}
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              lineHeight: "26.4px",
              fontFamily: "PolySans_Neutral",
            }}
          >
            Report a bug
          </Typography>
          <Box
            sx={{ marginLeft: "auto", cursor: "pointer" }}
            component={"div"}
            onClick={handleClose}
          >
            <Icons name={"minus"} width={24} height={24} />
          </Box>
        </Box>
        <BugReportDetails
          bugTitle={bugTitle}
          bugDescription={bugDescription}
          internetSpeed={internetSpeed}
          browserInfo={browserInfo}
          attachment={attachment}
          handleBugTitleChange={handleBugTitleChange}
          handleBugDescriptionChange={handleBugDescriptionChange}
          titleError={titleError}
          descriptionError={descriptionError}
          captureScreenshot={captureScreenshot}
          setAttachment={setAttachment}
          selectedSubModule={selectedSubModule}
          selectedType={selectedType}
        />
        <Box
          sx={{
            paddingX: "28px",
            paddingY: "16px",
            borderTop: "1px solid #EFEFEF",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Typography
              color={"#333"}
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "center",
                height: "44px",
                cursor: "pointer",
                paddingX: "20px",
                border: "1px solid #FFA737",
                borderRadius: "8px",
                marginRight: "12px",
                display: "flex",
                alignItems: "center",
              }}
              component={"div"}
              onClick={cancel}
            >
              Cancel
            </Typography>
            <Box sx={{ maxWidth: "168px" }}>
              <Button
                text="Submit bug report"
                textStyle={{ color: "#333" }}
                handleClick={isDisabled ? undefined : handleBugSubmit}
                disabled={isDisabled}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
