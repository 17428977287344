import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RelatedAosResourse from "../Video/RelatedAosResoure";
import { getFileTypesByAOSID } from "../../services/api/subject";
import { getToken } from "../../services/api/login";
import { getSubjectFormat } from "../../utils/utilfns";
import withErrorBoundary from "../../utils/withErrorBoundary";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography
            sx={{
              display: "flex",
              overflowX: "scroll",
            }}
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Chapter {
  id: number;
  file_id: number;
  seek_time: string;
  chapter: string;
  thumbnail_url: string;
}

interface ChaptersComponentProps {
  chapters: Chapter[];
  chaptersRecordings?: any;
  onVideoClick?: (url: string) => void;
  aos_id: string;
  subject_id: string;
  selectedTopic?: any;
  selectedModuleTypeId?: any;
  setBugRepId?: React.Dispatch<React.SetStateAction<string>>;
  setFileRepId?: React.Dispatch<React.SetStateAction<string>>;
  aosRelatedDetails?: any[];
  setRecordingData?: React.Dispatch<React.SetStateAction<any[]>>;
  setMarkComplete?: any;
  file_id?: any;
  setMarkTaskId?: React.Dispatch<React.SetStateAction<string>>;
  markTaskId?: string;
  videoid?: string;
  setThumbVideo?: React.Dispatch<React.SetStateAction<string>>;
  setVttSrc?: React.Dispatch<React.SetStateAction<string>>;
  selectedPage?:any;
  seekToVideo:(time:number)=>void
}

const RecordingTabs: React.FC<ChaptersComponentProps> = ({
  chapters,
  chaptersRecordings,
  onVideoClick,
  aos_id,
  subject_id,
  selectedTopic,
  selectedModuleTypeId,
  setBugRepId,
  setFileRepId,
  setRecordingData,
  setMarkComplete,
  file_id,
  setMarkTaskId,
  videoid,
  setThumbVideo,
  setVttSrc,
  selectedPage,
  seekToVideo
}) => {
  const verifyAccess = getToken();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 1 && !(chaptersRecordings && chaptersRecordings.length > 1)) {
      setValue(0);
    } else {
      setValue(newValue);
    }
  };
  const tabsData = [
    { label: "Chapters", index: 0 },
    { label: `All ${selectedPage?.currentPage?.area_of_study} recordings`, index: 1 },
  ];

  useEffect(() => {
    getSubjectAOSDetailsById(subject_id, aos_id);
  }, [selectedModuleTypeId, file_id]);

  const getSubjectAOSDetailsById = async (subject_id: any, aos_id: any) => {
    try {
      const payload = {
        subject_id: subject_id,
        aos_id: aos_id,
        topic_id: selectedTopic?.id,
        type_id: selectedModuleTypeId,
        file_id: file_id,
      };
      const response = await getFileTypesByAOSID(payload, verifyAccess.token);
      console.log("raju", response?.data?.data[0]?.files?.id);
      setRecordingData && setRecordingData(response?.data.data);
      setMarkComplete(response?.data.data[0]?.files.test_completion_status);
      setBugRepId && setBugRepId(response?.data?.data[0]?.files?.id);
      setMarkTaskId && setMarkTaskId(response?.data?.data[0]?.files?.task_id);
      setFileRepId && setFileRepId(response?.data?.data[0]?.files?.id);
      setThumbVideo && setThumbVideo(response?.data?.data[0]?.files?.thumbnail_url);
      setVttSrc && setVttSrc(response?.data?.data[0]?.files?.transcript_path);

      if (onVideoClick)
        onVideoClick(
          // `${response?.data?.data[0]?.files?.file_url_s3}`
          `${process.env.REACT_APP_CDN}/${response?.data?.data[0]?.files?.file_url}`
        );
    } catch (error) {
      console.error("Error fetching file type details:", error);
    }
  };

  const readTimestampsFromFile = async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const text = event.target.result;
        resolve(text);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };
  const handleSeekTime=(chapters:Chapter)=>{
    const timeString = chapters.seek_time;
    const timeParts = timeString.split(':');
    const minutes = parseInt(timeParts[0], 10);
    const seconds = parseInt(timeParts[1], 10);
    const totalSeconds = minutes * 60 + seconds;
    seekToVideo(totalSeconds)
  }
  console.log(readTimestampsFromFile);
  return (
    <Box sx={{ width: "100%", marginTop: "4px", overflowX: "hidden" }}>
      <Box
        sx={{
          maxWidth: "1000px",
          overflowX: "scroll",
          borderBottom: "3px",
          borderColor: "divider",
          ".MuiTabs-indicator": {
            background: "#FFA737 !important",
          },
          ".MuiTabs-scroller": {
            borderBottom: "1px solid #EFEFEF",
          },
        }}
      >
      {((chapters?.length)||0>0 && (chaptersRecordings?.length ||1)>1) && <Tabs
          sx={{
            ".MuiTabs-flexContainer": {
              display: "inline",
              overflowX: "scroll",
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabsData.filter((va,index)=>{
            if(index===0 && ((chapters?.length)||0>0)){
              return va
            }else if (index===1 && ((chaptersRecordings?.length ||1)>1)){

              return va
            }
          }).map((tab, index) => {
              return (
                <Tab
                  key={index}
                  sx={{
                    fontSize: { md: "16px", sm: "14px", xs: "14px" },
                    fontWeight: "600",
                    lineHeight: "24px",
                    color: "#696969",
                    paddingX: "12px",
                    paddingY: { md: "18px", sm: "10px", xs: "10px" },
                    "&.MuiButtonBase-root": {
                      textTransform: "none !important",
                    },
                    "&.MuiButtonBase-root.Mui-selected": {
                      color: "#333333",
                      fontSize: { md: "16px", sm: "14px", xs: "14px" },
                      fontWeight: "600",
                      lineHeight: "24px",
                    },
                  }}
                  label={tab.label}
                  {...a11yProps(tab.index)}
                  
                />
              );
          })}
        </Tabs>}
      </Box>
      {value === 0 && (
        <Box
          sx={{
            marginTop: { md: "34px", sm: "24px", xs: "24px" },
            overflowX: "scroll",
          }}
        >
          <div className="Container">
            <div style={{ display: "flex", gap: "6px" }}>
              {chapters.map((chapter, index) => (
                <Box
                  key={chapter.id}
                  sx={{
                    cursor: "pointer",
                    padding: "4px",
                    maxWidth: "143px",
                    borderRadius: "6px",
                    ":Hover": { background: "#FFEDD1" },
                  }}
                onClick={() => handleSeekTime(chapter)}
                >
                  <Box
                    sx={{
                      minWidth: "143px",
                      width:"100%",
                      height: "81px",
                      borderRadius: "6px",
                      border:
                        value === 0 && value === index
                          ? "1px solid #007AFF"
                          : "1px solid transparent",
                    }}
                    component="img"
                    src={`${process.env.REACT_APP_CDN}/${chapter.thumbnail_url}`}
                    alt="LoginSmily"
                    crossOrigin="anonymous"
                  />
                  <Box
                    sx={{
                      paddingY: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      color={"#696969"}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "18.2px",
                      }}
                    >
                      {chapter.seek_time}
                    </Typography>
                    <Box
                      sx={{
                        background: "#007AFF",
                        width: "100%",
                        height: "1px",
                      }}
                    />
                  </Box>
                  <Typography
                    color={"#333333"}
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "15px",
                      maxWidth: "127px",
                    }}
                  >
                    {chapter.chapter}
                  </Typography>
                </Box>
              ))}
            </div>
          </div>
        </Box>
      )}
      <CustomTabPanel value={value} index={1}>
        {chaptersRecordings &&
          chaptersRecordings
            ?.filter((item: any) => item.file_id !== videoid)
            .map((item2: any, index2: number) => (
              <RelatedAosResourse
                key={item2.file_name + index2}
                title={getSubjectFormat(item2.subject)}
                subtitle={item2.topic}
                type={item2.file_type}
                lastOpened={item2.last_opened}
                area_of_study_id={item2.area_of_study_id}
                duration={item2.duration}
                buttonText={`Open ${item2.filetype}`}
                imageUrl={item2.thumbnail_url}
                subject_id={item2.subject_id}
                file_id={item2.file_id}
                file_type_id={item2.file_type_id}
                topic_id={item2.topic_id}
                ext_type={item2.ext_type}
              />
            ))}
      </CustomTabPanel>
    </Box>
  );
};

export default withErrorBoundary(RecordingTabs,<>Something went wrong with RecordingTabs Component</>);
