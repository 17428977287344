import React from "react";
import { Box, TextField, Typography, Button } from "@mui/material";
import Icons from "../Icons";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface BugReportDetailsProps {
  internetSpeed?: string | null;
  bugTitle?: string;
  bugDescription?: string;
  browserInfo?: {
    userAgent: string;
    fullVersion: string;
  };
  attachment?: (File | string)[];
  handleBugTitleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBugDescriptionChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  captureScreenshot?: () => void;
  setAttachment?: React.Dispatch<React.SetStateAction<(File | string)[]>>;
  selectedSubModule?: {
    id: string;
    module: string;
  };
  selectedType?: string | undefined;
  titleError: boolean;
  descriptionError: boolean;
}

const BugReportDetails: React.FC<BugReportDetailsProps> = ({
  // internetSpeed,
  bugTitle,
  bugDescription,
  attachment,
  handleBugTitleChange,
  handleBugDescriptionChange,
  captureScreenshot,
  setAttachment,
  selectedSubModule,
  selectedType,
  titleError,
  descriptionError,
}) => {
  const [isDragging, setIsDragging] = React.useState(false);
  const [invalidType, setInvalidType] = React.useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const isValidFileType = (file: File) => {
    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    return allowedFileTypes.includes(file.type);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const validFiles = droppedFiles.filter((file) => isValidFileType(file));

    if (validFiles.length > 0) {
      setInvalidType(false);
      setIsDragging(false);
      setAttachment &&
        setAttachment((prevAttachment) => [
          ...(prevAttachment ?? []),
          ...validFiles,
        ]);
    } else {
      setInvalidType(true);
    }
  };

  const handleFileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.setAttribute("accept", ".png, .jpg, .jpeg");
      fileInput.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles) {
      const allowedFileTypes = ["image/png", "image/jpeg"];
      const validFiles = Array.from(selectedFiles).filter((file) =>
        allowedFileTypes.includes(file.type)
      );

      if (validFiles.length > 0) {
        setInvalidType(false);
        setAttachment &&
          setAttachment((prevAttachment) => [
            ...(prevAttachment ?? []),
            ...validFiles,
          ]);
      } else {
        setInvalidType(true);
      }
    }
  };

  const handleFileRemove = (indexToRemove: number) => {
    setAttachment &&
      setAttachment((prevAttachment) => {
        const updatedAttachment = [...prevAttachment];
        updatedAttachment.splice(indexToRemove, 1);
        return updatedAttachment;
      });
  };

  const [internetSpeed, setInternetSpeed] = React.useState<string | null>(null);
  
  const getInternetSpeed = async () => {
    const fileUrl = "public/1mb.pdf"; // A small test file (1MB)
    const fileSizeInBits = 1 * 8 * 1024 * 1024; // 1MB in bits
  
    const startTime = performance.now();
  
    try {
      const response = await fetch(fileUrl, { method: "GET", cache: "no-store" });
      await response.blob(); // Ensure full download
  
      const endTime = performance.now();
      const durationInSeconds = (endTime - startTime) / 1000;
      
      const speedMbps = fileSizeInBits / (durationInSeconds * 1_000_000); // Speed in Mbps
      const speedMBps = (speedMbps / 8).toFixed(2); // Convert to MB/s
  
      setInternetSpeed(`${speedMBps} Mbps`); // Display speed in MB/s
    } catch (error) {
      setInternetSpeed("Unable to detect speed");
    }
  };
  
      
        // Fetch the internet speed when the component mounts
        React.useEffect(() => {
          getInternetSpeed();
        }, []);

  return (
    <>
      <Box
        sx={{
          paddingY: { md: "24px", sm: "24px", xs: "12px" },
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          maxHeight: { md: "700px", sm: "700px", xs: "400px" },
          overflowX: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            paddingX: { md: "28px", sm: "28px", xs: "16px" },
          }}
        >
          <Icons name={"recordingBarIcon"} width={18} height={18} />
          <Typography
            color={"#333"}
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "18.2px",
            }}
            component={"div"}
            onClick={() => console.log("Save")}
          >
            {selectedSubModule?.module.replace('-', '')}
            {selectedType && ' - ' + selectedType.charAt(0).toUpperCase()}
            {selectedType && selectedType.substring(1)}
          </Typography>
        </Box>

        <Typography
          color={"#333"}
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "18.2px",
            paddingX: { md: "28px", sm: "28px", xs: "16px" },
          }}
        >
          Please add a clear title and descriptions for the bug you’ve
          identified.
        </Typography>

        <Box
          sx={{
            padding: "12px",
            background: "#F4F6FF",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginX: { md: "28px", sm: "28px", xs: "16px" },
          }}
        >
          <Typography
            color={"#333"}
            sx={{
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "15px",
            }}
          >
            Example:
          </Typography>
          <Typography
            color={"#333"}
            sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "18.2px" }}
          >
            Title:
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "15px",
                fontStyle: "italic",
                marginLeft: "2px",
                color: "#333",
              }}
            >
              Missing page
            </span>
          </Typography>
          <Typography
            color={"#333"}
            sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "18.2px" }}
          >
            Description:
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "15px",
                fontStyle: "italic",
                marginLeft: "2px",
                color: "#333",
              }}
            >
              There’s missing content between pages 7 and 8.
            </span>
          </Typography>
        </Box>

        <Box sx={{ paddingX: { md: "28px", sm: "28px", xs: "16px" } }}>
           {/* Internet speed display */}
      {internetSpeed && (
        <Typography
          color={"#333"}
          sx={{
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "18.2px",
            marginBottom: "8px",
          }}
        >
          Internet Speed: {internetSpeed}
        </Typography>
      )}
          <Typography
            color={"#333333"}
            variant="detailBold"
            sx={{ display: "block", marginBottom: "4px" }}
          >
            Bug Title
          </Typography>
          <TextField
            id="title"
            variant="outlined"
            value={bugTitle}
            inputProps={{ maxLength: 50 }}
            onChange={handleBugTitleChange}
            sx={{
              color: "#333",
              marginBottom: "16px",
              width: "100%",
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
                border: "1px solid #D2D2D0",
              },
              ".MuiInputBase-input": {
                padding: "12px 14px !important",
              },
              ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                { border: "1px solid #C61341" },
              "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                color: "#B21200",
              },
              "& .css-5ifmys-MuiFormHelperText-root": { marginLeft: 0 },
              "&:hover": { background: "#FFF8EC" },
              ".css-19z3763-MuiInputBase-input-MuiOutlinedInput-input": {
                color: "#333",
                fontSize: "14px",
                lineHeight: "18.2px",
              },
            }}
          />
          {titleError && (
            <Typography
              color={"#B21200"}
              sx={{
                fontSize: "12px",
                marginBottom: "10px",
                marginTop: "-18px",
              }}
            >
              Invalid title
            </Typography>
          )}
          <Typography
            color={"#333333"}
            variant="detailBold"
            sx={{ display: "block", marginBottom: "4px" }}
          >
            Bug Description
          </Typography>
          <TextField
            type="description"
            multiline
            rows={6}
            inputProps={{ maxLength: 250 }}
            maxRows={6}
            value={bugDescription}
            onChange={handleBugDescriptionChange}
            variant="outlined"
            sx={{
              color: "#333",
              width: "100%",
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
                border: "1px solid #D2D2D0",
              },
              ".MuiInputBase-root": {
                padding: "12px 14px",
              },
              ".MuiInputBase-input": {
                paddingTop: "7px",
              },
              ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                { border: "1px solid #C61341" },
              "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                color: "#B21200",
              },
              "& .css-5ifmys-MuiFormHelperText-root": { marginLeft: 0 },
              "&:hover": { background: "#FFF8EC" },
              "& .MuiInputBase-input":{
                color: "#333",
                fontSize: "14px",
                lineHeight: "18.2px",
              }
            }}
          />
          {descriptionError && (
            <Typography color={"#B21200"} sx={{ fontSize: "12px" }}>
              Invalid description
            </Typography>
          )}
        </Box>
        <Box sx={{ paddingLeft: { md: "28px", sm: "28px", xs: "16px" } }}>
          <Typography
            color={"#333333"}
            variant="detailBold"
            sx={{ display: "block", marginBottom: "4px" }}
          >
            Attachments
          </Typography>
          <Typography
            color={"#696969"}
            variant="detailBold"
            sx={{ display: "block", marginBottom: "4px" }}
          >
            Take a screenshot of the bug.
          </Typography>
          <Box
            sx={{
              display: "-webkit-box",
              alignItems: "center",
              gap: "12px",
              marginTop: "12px",
              maxWidth: "100%",
              overflowX: "scroll",
            }}
          >
            {attachment && attachment?.length > 0 && (
              <>
                {attachment &&
                  attachment.map((file, index) => (
                    <Box
                      sx={{
                        width: "150px",
                        height: "154px",
                        borderRadius: "8px",
                        position: "relative",
                        objectFit: "cover",
                      }}
                      key={index}
                    >
                      <Box>
                        {typeof file === "string" ? (
                          <img
                            src={file}
                            alt={`screenshot_${index + 1}`}
                            style={{
                              width: "100%",
                              height: "150px",
                              borderRadius: "8px",
                            }}
                          />
                        ) : (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            style={{
                              width: "100%",
                              height: "150px",
                              borderRadius: "8px",
                            }}
                          />
                        )}
                        <Button
                          onClick={() => handleFileRemove(index)}
                          sx={{
                            position: "absolute",
                            right: "-6px",
                            cursor: "pointer",
                            background: "#8E8D8A",
                            minWidth: "20px",
                            height: "20px",
                            padding: "0px",
                            color: "#fff",
                            fontWeight: "600",
                            "&:hover": {
                              background: "#8E8D8A",
                              color: "#fff",
                            },
                          }}
                        >
                          X
                        </Button>
                      </Box>
                    </Box>
                  ))}
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "25px",
              marginTop: "12px",
              maxWidth: "100%",
              overflowX: "scroll",
            }}
          >
            <Box
              sx={{
                paddingX: "12px",
                paddingY: "24px",
                borderRadius: "8px",
                border: "2px dashed #D2D2D0",
                backgroundColor: isDragging ? "#007AFF" : "",
                height: "102px",
                maxWidth: "126px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
                "&:hover": {
                  backgroundColor: "#FFF8EC",
                },
              }}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleFileClick}
            >
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="cloud-upload"
                width="24"
                height="24"
                fill="#8E8D8A"
                aria-hidden="true"
              >
                <path d="M518.3 459a8 8 0 00-12.6 0l-112 141.7a7.98 7.98 0 006.3 12.9h73.9V856c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V613.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 459z"></path>
                <path d="M811.4 366.7C765.6 245.9 648.9 160 512.2 160S258.8 245.8 213 366.6C127.3 389.1 64 467.2 64 560c0 110.5 89.5 200 199.9 200H304c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8h-40.1c-33.7 0-65.4-13.4-89-37.7-23.5-24.2-36-56.8-34.9-90.6.9-26.4 9.9-51.2 26.2-72.1 16.7-21.3 40.1-36.8 66.1-43.7l37.9-9.9 13.9-36.6c8.6-22.8 20.6-44.1 35.7-63.4a245.6 245.6 0 0152.4-49.9c41.1-28.9 89.5-44.2 140-44.2s98.9 15.3 140 44.2c19.9 14 37.5 30.8 52.4 49.9 15.1 19.3 27.1 40.7 35.7 63.4l13.8 36.5 37.8 10C846.1 454.5 884 503.8 884 560c0 33.1-12.9 64.3-36.3 87.7a123.07 123.07 0 01-87.6 36.3H720c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h40.1C870.5 760 960 670.5 960 560c0-92.7-63.1-170.7-148.6-193.3z"></path>
              </svg>
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{
                  display: "block",
                  marginBottom: "4px",
                  marginTop: "12px",
                }}
              >
                Upload a file
              </Typography>
              <Typography
                color={"#696969"}
                sx={{
                  display: "block",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "15px",
                  textAlign: "center",
                }}
              >
                Click to browse, or drag & drop a file here
              </Typography>
              {invalidType && (
                <Typography
                  sx={{
                    position: "absolute",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "center",
                    color: "red",
                    lineHeight: "15px",
                    bottom: "7px",
                  }}
                >
                  Please upload only png/jpg file.
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                paddingX: "12px",
                paddingY: "24px",
                borderRadius: "8px",
                border: "1px solid #FFA737",
                height: "102px",
                maxWidth: "126px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#FFF8EC",
                cursor: "pointer",
              }}
              onClick={captureScreenshot}
            >
              <Icons name={"cameraOutline"} width={24} height={24} />
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{
                  display: "block",
                  marginBottom: "4px",
                  marginTop: "12px",
                }}
              >
                Take a screenshot
              </Typography>
              <Typography
                color={"#696969"}
                sx={{
                  display: "block",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "15px",
                  textAlign: "center",
                }}
              >
                Take a photo of your current file preview
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withErrorBoundary(BugReportDetails,<>Something went wrong with BugReportDetails Component</>);
   