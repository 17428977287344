import {
  Box,
  Grid,
  LinearProgress,
  Pagination,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Dashboard from "../../layouts/Dashboard";
import FilteredComponent from "../../components/SeachResults/FilterComponent";
import IconComponent from "../../components/Icons";
import Button from "../../components/Button";
import MobileFilterComponent from "../../components/SeachResults/MobileFilterComponent";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { globalSearch } from "../../services/api/subject";
import { getToken } from "../../services/api/login";
import { getSubjectFormat, secondsToDuration } from "../../utils/utilfns";
import NoImageFound from "../../assets/images/NoImageFound2.png"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: "-4px",
  height: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const SearchResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialSearchData = location.state?.searchData || [];
  const searchQuery = location.state?.searchQuery || "";
  const [selectedOptions1, setSelectedOptions1] = useState<any[]>([]);
  const [selectedOptions4, setSelectedOptions4] = useState<any[]>([]);
  const [searchData, setSearchData] = useState(searchQuery);
  const [filterData, setFilterData] = useState(initialSearchData);
  const [pageCount, setPageCount] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [totalDataa, setTotalDataa] = useState<any>()

  const resetSearchData = () => {
    setSearchData("");
    setFilterData([]);
  };

  const verifyAccess = getToken();
  const transformPayload = (payload: any) => {
    const transformedPayload = {
      FileType: Array.isArray(payload.FileType)
        ? payload.FileType.map((type: any) => type.toLowerCase())
        : [payload.FileType.toLowerCase()],
      Subject: Array.isArray(payload.Subject)
        ? payload.Subject.map((subject: any) => subject.toLowerCase())
        : [payload.Subject.toLowerCase()],
      Search: payload.Search || "",
      page: payload.page,
      limit: payload.limit,
    };

    return transformedPayload;
  };

  const handleSearchChanges = async () => {
    try {
      const token = verifyAccess.token;
      const transformedPayload = transformPayload({
        FileType: selectedOptions4,
        Subject: selectedOptions1,
        Search: searchQuery,
        page: pageCount,
        limit: pageLimit,
      });

      const response = await globalSearch(transformedPayload, token);
      setFilterData(response.data);
      setTotalDataa(response.total_count)
    } catch (error) {
      console.error("error:", error);
    }
  };
  console.log('setTotalDataa', totalDataa)
  useEffect(() => {
    handleSearchChanges();
  }, [selectedOptions1, selectedOptions4, searchQuery]);

  const handleContent = (subject: any) => {
    console.log("handle", subject)
    const newSearch = new URLSearchParams();
    newSearch.set('sid', subject.subject_id);
    newSearch.set('aosID', subject.area_of_study_id);
    newSearch.set('topicID', subject.topic_id);
    newSearch.set('typeID', subject.type_id);
    newSearch.set('fileID', subject.id);
    navigate(`/aos?${newSearch.toString()}`);
  };

  console.log("datttttttt", filterData);

  const handleChangePage = async (event: any, value: any) => {
    setPageCount(value);
    setPageLimit(pageLimit);
    try {
      const token = verifyAccess.token;
      const transformedPayload = transformPayload({
        FileType: selectedOptions4,
        Subject: selectedOptions1,
        Search: searchQuery,
        page: value,
        limit: pageLimit,
      });
      const response = await globalSearch(transformedPayload, token);
      setFilterData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Dashboard showPopover={false}>
      <Box
        sx={{
          paddingX: { md: "204px", sm: "100px", xs: "16px" },
          paddingY: { md: "50px", sm: "24px", xs: "16px" },
          height: { md: "auto", sm: "auto", xs: "calc(100vh - 70px)" },
          overflowY: { md: "auto", sm: "auto", xs: "hidden" },

          "@media screen and (max-width:1130px)": {
            paddingX: "16px",
          },
        }}
      >
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Typography
              variant="title1"
              color={"#333333"}
              sx={{ fontSize: { md: "28px", sm: "20px", xs: "20px" } }}
            >
              <Typography
                variant="title1"
                color={"#696969"}
                sx={{ fontSize: { md: "28px", sm: "20px", xs: "20px" } }}
              >
                {" "}
                Search results for:{" "}
              </Typography>
              {searchData}
            </Typography>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            marginTop={{ md: "21px", sm: "21px", xs: "0px" }}
            display={"flex"}
            alignItems={"center"}
            gap={"8px"}
          >
            <Typography
              variant="detail"
              color={"#333333"}
              fontWeight={"500"}
              display={{ md: "flex", sm: "flex", xs: "none" }}
            >
              Filter by
            </Typography>
            <FilteredComponent
              selectedOptions1={selectedOptions1}
              setSelectedOptions1={setSelectedOptions1}
              selectedOptions4={selectedOptions4}
              setSelectedOptions4={setSelectedOptions4}
              handleSearchChange={handleSearchChanges}
              resetSearchData={resetSearchData}
            />
            <MobileFilterComponent selectedOptions1={selectedOptions1}
              setSelectedOptions1={setSelectedOptions1}
              selectedOptions4={selectedOptions4}
              setSelectedOptions4={setSelectedOptions4}
              handleSearchChange={handleSearchChanges}
              resetSearchData={resetSearchData} />
          </Grid>

          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            marginY={{ md: "20px", sm: "20px", xs: "8px" }}
          >
            <Typography variant="detail" color={"#333333"} fontWeight={"500"}>
              {selectedOptions1.length + selectedOptions4.length} Filter applied
              | {totalDataa}
              {" "}  files found
            </Typography>
          </Grid>

          <Grid
            container
            sx={{
              overflowY: { ms: "hidden", sm: "hidden", xs: "auto" },
              height: { md: "auto", sm: "auto", xs: "calc(100vh - 172px)" },
              paddingBottom: { md: "0", xs: "30px", sm: "0" }
            }}
          >
            {filterData.map((item: any, index: any) => (
              <Grid
                key={index}
                item
                md={3}
                sm={3}
                xs={12}
                marginTop={{ md: "0px", sm: "16px", xs: "8px" }}
                display={{ md: "block", sm: "block", xs: "block" }}

              >
                <Box
                  sx={{
                    paddingX: { md: "8px", sm: "8px", xs: "0px" },
                    cursor: "pointer",
                  }}
                  onClick={() => handleContent(item)}
                >
                  <Box
                    className="hoverDiv"
                    sx={{
                      borderRadius: { md: "12px", sm: "12px", xs: "0px" },
                      marginBottom: { md: "20px", sm: "20px", xs: "8px" },
                      width: "100%",
                      display: { md: "block", sm: "block", xs: "flex" },
                      border: {
                        md: "2px solid #d2d2d0",
                        sm: "2px solid #d2d2d0",
                        xs: "0px",
                      },


                    }}
                  >
                    {item.ext_type === "pdf" && (
                      <Box
                        sx={{
                          position: "relative",
                          maxWidth: { md: "100%", sm: "100%", xs: "156px" },
                        }}
                      >

                        <Box
                          className="hoverOpacity"
                          sx={{
                            paddingTop: "13px",
                            paddingX: "21px",
                            background: "#F4F6FF",
                            borderTopLeftRadius: {
                              md: "12px",
                              sm: "12px",
                              xs: "0",
                            },
                            borderTopRightRadius: {
                              md: "12px",
                              sm: "12px",
                              xs: "0",
                            },
                            position: "relative",
                          }}
                          component={"div"}
                          onClick={() => console.log("Open workbook1")}
                        >
                          <Box
                            sx={{
                              width: "100%", objectFit: "cover", objectPosition: "center",
                              height: "123px",
                              "@media screen and (max-width:600px)": {
                                width: "100px",
                                height: "78px",
                                objectFit: "cover",
                                objectPosition: "center"
                              },
                            }}
                            component="img"
                            src={`${process.env.REACT_APP_CDN}/${item.thumbnail_url}`}
                            alt="LoginSmily"
                            crossOrigin="anonymous"
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.onerror = null; // Prevents infinite loop if fallback image fails
                              target.src = NoImageFound;
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: { md: "block ", sm: "block", xs: "none" },
                          }}
                        >
                          <Box
                            className="hoverShow"
                            sx={{
                              display: "none",
                              maxWidth: "168px",
                              position: "absolute",
                              bottom: "50%",
                              width: "100%",
                              left: "50%",
                              transform: "translate(-50%, 50%)",
                            }}
                          >
                            <Button
                              text={`Open ${item.file_type}`}
                              textStyle={{ color: "#333" }}
                              handleClick={() => console.log("Open workbook1")}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {item.ext_type === "video" && (
                      <Box
                        sx={{
                          position: "relative",
                          maxWidth: { md: "100%", sm: "100%", xs: "156px" },
                        }}
                      >
                        <Box
                          sx={{
                            background: "#F4F6FF",
                            borderTopLeftRadius: {
                              md: "12px",
                              sm: "12px",
                              xs: "0",
                            },
                            borderTopRightRadius: {
                              md: "12px",
                              sm: "12px",
                              xs: "0",
                            },
                            position: "relative",
                          }}
                          component={"div"}
                          onClick={() => console.log("Open workbook1")}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              height: "137px",
                              objectFit: "cover",
                              objectPosition: "top",
                              borderTopLeftRadius: {
                                md: "12px",
                                sm: "12px",
                                xs: "0",
                              },
                              borderTopRightRadius: {
                                md: "12px",
                                sm: "12px",
                                xs: "0",
                              },
                              "@media screen and (max-width:600px)": {
                                width: "141px",
                                height: "94px",
                                objectFit: "cover",
                                objectPosition: "center"
                              },
                            }}

                            component="img"
                            crossOrigin="anonymous"
                            src={`${process.env.REACT_APP_CDN}/${item.thumbnail_url}`}
                            alt="LoginSmily"
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.onerror = null; // Prevents infinite loop if fallback image fails
                              target.src = NoImageFound;
                            }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "50%",
                              left: "50%",
                              transform: "translate(-50%, 50%)",
                            }}
                            component={"div"}
                            onClick={() => console.log("Video")}
                          >
                            <IconComponent
                              name="videControl"
                              fill={"#8E8D8A"}
                            />
                          </Box>
                        </Box>
                        <Box
                          className="hoverVideControl"
                          sx={{
                            display: "none",
                            position: "absolute",
                            bottom: "50%",
                            left: "50%",
                            transform: "translate(-50%, 50%)",
                            cursor: "pointer",
                          }}
                          component={"div"}
                          onClick={() => console.log("Video")}
                        >
                          <IconComponent name="videControl" fill={"#8E8D8A"} />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "15px",
                            color: "#FFF",
                            position: "absolute",
                            right: "8px",
                            top: "8px",
                            background: "#2B3258",
                            paddingX: "4px",
                            borderRadius: "8px",
                          }}
                        >
                          {secondsToDuration(item?.duration)}
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={50}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        paddingX: { md: "16px", sm: "16px", xs: "12px" },
                        paddingY: { md: "16px", sm: "16px", xs: "8.5px" },
                      }}
                    >
                      <Typography
                        sx={{
                          paddingX: "8px",
                          paddingY: "4px",
                          background: "#FFF0E2",
                          borderRadius: "4px",
                          "@media screen and (max-width:374px)": {
                            fontSize: "8px",
                          },
                        }}
                        variant="detail"
                        color={"#333333"}
                        fontWeight={500}
                        lineHeight={"15px"}
                      >
                        {getSubjectFormat(item.subject)}
                      </Typography>
                      <Typography
                        sx={{
                          display: "-webkit-box",
                          fontSize: { md: "14px", sm: "14px", xs: "12px" },
                          fontWeight: "600",
                          lineHeight: "18.2px",
                          marginTop: "8px",
                          color: "#000000",
                          maxHeight: '38px',
                          minHeight: '38px',
                          overflow: 'hidden',
                          '-webkit-line-clamp': '2',
                          '-webkit-box-orient': 'vertical',
                          textOverflow: 'ellipsis',
                          "@media screen and (max-width:374px)": {
                            fontSize: "8px",
                          },
                        }}
                      >
                        {item.topic}
                      </Typography>
                      <Typography
                        sx={{
                          display: { md: "block", sm: "block", xs: "none" },
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "18.2px",
                          marginTop: "4px",
                          color: "#333333",
                          maxHeight: '18px',
                          minHeight: '18px',
                          overflow: 'hidden',
                          '-webkit-line-clamp': 1,
                          whiteSpace: "nowrap",
                          '-webkit-box-orient': 'vertical',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item.file_type}
                      </Typography>

                      <Typography
                        sx={{
                          display: "block",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "18.2px",
                          marginTop: "4px",
                          color: "#696969",
                          height: "18px",
                          "@media screen and (max-width:374px)": {
                            fontSize: "8px",
                          },
                        }}
                      >
                        {item.last_opened !== null ? `Last opened: ${moment(moment.utc(item.last_opened).toDate()).local().fromNow()}` : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Stack sx={{ width: "100%", marginTop: { md: "0", sm: "0", xs: "-50px", } }} spacing={2}>
            <Pagination
              count={Math.ceil(totalDataa / pageLimit)}
              onChange={handleChangePage}
              defaultPage={1}
              sx={{
                ".Mui-selected": { background: "#007AFF !important", color: "white !important", },
                '.MuiPagination-ul': {
                  display: "flex", justifyContent: "center", background: "#FFF", zIndex: "100", position: "relative",
                  paddingY: { md: "0", sm: "0", xs: "10px", },
                },
              }}
            />
          </Stack>
        </Grid>
      </Box>
    </Dashboard>
  );
};

export default SearchResults;
