import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Icons from "../Icons";
import { getToken, resetPassword } from "../../services/api/login";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { updateProfile } from "../../services/api/profile";

type ToastState = {
  type: string;
  message: string;
};
interface settingPopupProps {
  resetPass: boolean;
  setResetPass: Dispatch<SetStateAction<boolean>>;
  setPassUpdatePopup: Dispatch<SetStateAction<boolean>>;
  fetchData: () => void;
  fName: string;
  lName: string;
  setToast: Dispatch<SetStateAction<ToastState | null>>;
  setBottomDrawerOpen2: Dispatch<SetStateAction<boolean>>;
}

interface FormData {
  newPassword: string;
  confirmPassword: string;
}

const MobileSettings = ({
  resetPass,
  setResetPass,
  setPassUpdatePopup,
  setBottomDrawerOpen2,
  fetchData,
  fName,
  lName,
  setToast,
}: settingPopupProps) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);
  const [passwordsMatch, setPasswordsMatch] = React.useState(true);
  const [passwordsErr, setPasswordsErr] = React.useState(true);
  const [formData, setFormData] = React.useState<FormData>({
    newPassword: "",
    confirmPassword: "",
  });
  const [btnText, setBtnText] = React.useState("Save");
  const navigate = useNavigate();
  const isValidFirstName = (name: string) =>
    /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(name);
  const isValidLastName = (name: string) =>
    /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(name);
  const verifyAccess = getToken();
  const [firstName, setFirstName] = React.useState(fName);
  const [lastName, setLastName] = React.useState(lName);
  const [isValidFName, setIsValidFName] = React.useState(true);
  const [isValidLName, setIsValidLName] = React.useState(true);
  const formRef= React.useRef<HTMLFormElement>(null);
  const validatePassword = (password: string): boolean => {
    const specialChars =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    return specialChars.test(password);
  };

  const handleBack = () => {
    setResetPass(false);
    setFormData({
      newPassword: "",
      confirmPassword: "",
    });
    setShowPassword(false);
    setShowPassword1(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleTogglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const handleReset = () => {
    setResetPass(true);
  };

  const handleInputNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, value } = event.target;
    if (id === "firstName") {
      setFirstName(value);
      setIsValidFName(isValidFirstName(value));
    }

    if (id === "lastName") {
      setLastName(value);
      setIsValidLName(isValidLastName(value));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

    if (id === "newPassword") {
      setIsPasswordValid(validatePassword(value));
      if (formData.confirmPassword && formData.confirmPassword === value) {
        setPasswordsMatch(true);
      } else {
        setPasswordsMatch(false);
      }
    }

    if (id === "confirmPassword") {
      if (formData.newPassword && formData.newPassword === value) {
        setPasswordsMatch(true);
        setPasswordsErr(true);
      } else {
        setPasswordsMatch(false);
        setPasswordsErr(false);
      }
    }
  };

  useEffect(() => {
    if (resetPass) {
      setBtnText("Save New Password");
    } else {
      setBtnText("Save");
    }
  }, [resetPass]);

  const handleResetPassword = async (e:any) => {
    e.preventDefault()
    if (formData.newPassword === "") {
      setIsPasswordValid(false);
    }
    if (formData.confirmPassword === "") {
      setPasswordsErr(false);
    }
    if (
      formData.newPassword.trim() !== "" &&
      formData.confirmPassword.trim() !== "" &&
      passwordsMatch
    ) {
      try {
        const response = await resetPassword(
          {
            newpwd: formData.newPassword,
            confirmpwd: formData.confirmPassword,
          },
          verifyAccess.token
        );
        if (response && response.data) {
          setResetPass(false);
          setFormData({
            newPassword: "",
            confirmPassword: "",
          });
          setShowPassword(false);
          setShowPassword1(false);
          setPassUpdatePopup(true);
          setTimeout(() => {
            Cookies.remove("loginToken");
            Cookies.remove("authAccess");
            navigate("/");
          }, 5000);
        } else {
          // const errorMessage =
          //   response && response.data.message
          //     ? response.data.message
          //     : "An error occured";
          // setLoading(false);
          // setToast({
          //   type: "error",
          //   message: errorMessage,
          // });
        }
      } catch (error) {
        console.error("Forgot api", error);
      }
    }
  };

  const updataProfileData = async (e:any) => {
    e.preventDefault()
    if (firstName === "") {
      setIsValidFName(false);
    }
    if (lastName === "") {
      setIsValidLName(false);
    }
    if (
      (firstName.trim() !== fName || lastName.trim() !== lName) &&
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      isValidFName &&
      isValidLName
    ) {
      try {
        const response = await updateProfile(
          { first_name: firstName, last_name: lastName },
          verifyAccess.token
        );
        if (response && response.data) {
          fetchData();
          setBottomDrawerOpen2(false);
          setResetPass(false);
          setFormData({
            newPassword: "",
            confirmPassword: "",
          });
          setShowPassword(false);
          setShowPassword1(false);
          const successMessage = response.data.message;
          setToast({
            type: "success",
            message: successMessage,
          });
        } else {
          const errorMessage =
            response && response.data.message
              ? response.data.message
              : "An error occured";
          setToast({
            type: "error",
            message: errorMessage,
          });
          setBottomDrawerOpen2(false);
        }
      } catch (error) {
        console.error("Forgot api", error);
      }
    } else {
      setBottomDrawerOpen2(false);
    }
  };

  return (
    <form ref={formRef} onSubmit={(e) => {resetPass ? handleResetPassword(e) : updataProfileData(e)}}>
      {!resetPass ? (
        <Box sx={{ paddingY: "16px" }}>
          <Box sx={{ marginBottom: "20px", paddingX: "16px" }}>
            <Typography
              color={"#333"}
              sx={{
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Account
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "12px",
                }}
              >
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "15px",
                    marginBottom: "4px",
                  }}
                >
                  First Name
                </label>
                <TextField
                  id="firstName"
                  variant="outlined"
                  value={firstName}
                  onChange={handleInputNameChange}
                  sx={{
                    ".Mui-focused": {
                      background: "#FFF8EC",
                      ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        color: "#333",
                        fontSize: "14px",
                        fontWeight: "400",
                      },
                    },
                    ".MuiInputBase-root": {
                      maxHeight: "44px",
                      borderRadius: "8px",
                      "&:focus-visible": {
                        outline: "none !important",
                      },
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333",
                      padding: "12px 16px",
                      textTransform: "capitalize",
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid #D2D2D0",
                    },
                    ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                      padding: "0px",
                      marginLeft: "-7px",
                      pointerEvents: "none",
                    },
                  }}
                />
                {!isValidFName && (
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icons
                      name="warningRed"
                      fill={"#8E8D8A"}
                      style={{ marginRight: "6px" }}
                    />{" "}
                    {firstName === ""
                      ? "Enter first name"
                      : "Invalid first name"}
                  </span>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px 0px",
                }}
              >
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    marginBottom: "4px",
                  }}
                >
                  Last Name
                </label>
                <TextField
                  id="lastName"
                  variant="outlined"
                  value={lastName}
                  onChange={handleInputNameChange}
                  sx={{
                    ".Mui-focused": {
                      background: "#FFF8EC",
                      ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        color: "#333",
                        fontSize: "14px",
                        fontWeight: "400",
                      },
                    },
                    ".MuiInputBase-root": {
                      maxHeight: "44px",
                      borderRadius: "8px",
                      "&:focus-visible": {
                        outline: "none !important",
                      },
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333",
                      padding: "12px 16px",
                      textTransform: "capitalize",
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid #D2D2D0",
                    },
                    ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                      padding: "0px",
                      marginLeft: "-7px",
                      pointerEvents: "none",
                    },
                  }}
                />
                {!isValidLName && (
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icons
                      name="warningRed"
                      fill={"#8E8D8A"}
                      style={{ marginRight: "6px" }}
                    />{" "}
                    {lastName === "" ? "Enter last name" : "Invalid last name"}
                  </span>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    marginBottom: "4px",
                  }}
                >
                  Email
                </label>
                <TextField
                  id="email"
                  variant="outlined"
                  value={verifyAccess?.responseData.email}
                  disabled
                  sx={{
                    ".Mui-focused": {
                      background: "#FFF8EC",
                      ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                      {
                        background: "transparent",
                      },
                      ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        color: "#333",
                        fontSize: "14px",
                        fontWeight: "400",
                      },
                    },
                    ".MuiInputBase-root": {
                      maxHeight: "44px",
                      borderRadius: "8px",
                      "&:focus-visible": {
                        outline: "none !important",
                      },
                    },
                    "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333",
                      padding: "12px 16px",
                    },
                    ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                      padding: "0px",
                      marginLeft: "-7px",
                      pointerEvents: "none",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              color={"#333"}
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                paddingX: "16px",
              }}
            >
              Security
            </Typography>
            <Box
              sx={{
                borderBottom: "0.9px solid #D2D2D0",
                padding: "16px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                paddingX: "16px",
              }}
              onClick={handleReset}
            >
              <Typography
                color={"#333"}
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Reset password
              </Typography>
              <Icons name="chevronRight" fill="#8E8D8A" />
            </Box>
            <Box
              sx={{
                background: "#FFA737",
                borderRadius: "8px",
                marginTop: "16px",
                borderTop: "1px solid #EFEFEF",
                marginX: "16px",
              }}
            >
              <Typography
                color={"#333"}
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  textAlign: "center",
                  paddingY: "10px",
                  cursor: "pointer",
                }}
                component={"div"}
                onClick={() => formRef.current?.requestSubmit()}
              >
                Save
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              paddingX: "16px",
              paddingTop: "24px",
              minHeight: "300px",
              borderBottom: "1px solid #EFEFEF",
            }}
          >
            <Typography
              color={"#333"}
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginBottom: "20px",
                width: "fit-content",
              }}
              onClick={handleBack}
            >
              <Icons name="chevronLeft" fill="#8E8D8A" width={16} height={16} />{" "}
              <span
                style={{
                  borderBottom: "2px solid #FFA737",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                Back
              </span>
            </Typography>
            <Typography
              color={"#333"}
              sx={{
                fontSize: "22px",
                fontWeight: "600",
                marginBottom: "20px",
                fontFamily: "PolySans_Neutral",
              }}
            >
              Reset your password
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  marginBottom: "4px",
                }}
              >
                New password
              </label>
              <TextField
                id="newPassword"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                onChange={handleInputChange}
                value={formData.newPassword}
                sx={{
                  ".Mui-focused": {
                    ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover": {
                      background: "transparent",
                    },
                    ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                    {
                      background: "transparent",
                    },
                    ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input": {
                      color: "#333",
                      fontSize: "14px",
                      fontWeight: "400",
                    },
                  },
                  ".MuiInputBase-root": {
                    maxHeight: "44px",
                    borderRadius: "8px",
                    "&:focus-visible": {
                      outline: "none !important",
                    },
                  },
                  "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#333",
                    padding: "12px 16px",
                  },
                  "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                  {
                    border:
                      formData.newPassword === ""
                        ? "1px solid #D2D2D0"
                        : passwordsMatch
                          ? "1px solid #007634"
                          : "1px solid #C61341",
                  },
                  "&.MuiFormControl-root .MuiInputBase-root .css-70vwbq-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    color: "#333",
                  },
                  ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                    padding: "0px",
                    marginLeft: "-7px",
                    pointerEvents: "none",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <Icons name="show" fill={"#8E8D8A"} />
                        ) : (
                          <Icons name="hide" fill={"#8E8D8A"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {!isPasswordValid && (
                <span
                  style={{
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    marginTop: "4px"
                  }}
                >
                  <Icons
                    name="warningRed"
                    fill={"#8E8D8A"}
                    style={{ marginRight: "6px" }}
                  />{" "}
                  {formData.newPassword === ""
                    ? "Enter new password"
                    : "Password: 8+ chars, 1 uppercase, 1 lowercase, 1 digit, 1 special."}
                </span>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "12px",
              }}
            >
              <label
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  marginBottom: "4px",
                }}
              >
                Confirm password
              </label>
              <TextField
                id="confirmPassword"
                variant="outlined"
                type={showPassword1 ? "text" : "password"}
                onChange={handleInputChange}
                value={formData.confirmPassword}
                sx={{
                  ".Mui-focused": {
                    ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover": {
                      background: "transparent",
                    },
                    ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                    {
                      background: "transparent",
                    },
                    ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input": {
                      color: "#333",
                      fontSize: "14px",
                      fontWeight: "400",
                    },
                  },
                  ".MuiInputBase-root": {
                    maxHeight: "44px",
                    borderRadius: "8px",
                    "&:focus-visible": {
                      outline: "none !important",
                    },
                  },
                  "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#333",
                    padding: "12px 16px",
                  },
                  "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                  {
                    border:
                      formData.confirmPassword === ""
                        ? "1px solid #D2D2D0"
                        : passwordsMatch
                          ? "1px solid #007634"
                          : "1px solid #C61341",
                  },
                  "&.MuiFormControl-root .MuiInputBase-root .css-70vwbq-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    color: "#333",
                  },
                  ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                    padding: "0px",
                    marginLeft: "-7px",
                    pointerEvents: "none",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility1}
                        edge="end"
                      >
                        {showPassword1 ? (
                          <Icons name="show" fill={"#8E8D8A"} />
                        ) : (
                          <Icons name="hide" fill={"#8E8D8A"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled={!validatePassword(formData.newPassword)}
              />
              {!passwordsErr && (
                <span
                  style={{
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    marginTop: "4px"
                  }}
                >
                  <Icons
                    name="warningRed"
                    fill={"#8E8D8A"}
                    style={{ marginRight: "6px" }}
                  />{" "}
                  {formData.confirmPassword === ""
                    ? "Enter confirm password"
                    : "Passwords don’t match"}
                </span>
              )}
              {passwordsMatch && formData.confirmPassword !== "" && (
                <span
                  style={{
                    color: "green",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icons
                    name="greenCheckCircle"
                    fill={"#007634"}
                    style={{
                      marginRight: "6px",
                      width: "17px",
                      height: "17px",
                    }}
                  />{" "}
                  Passwords match
                </span>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              background: "#FFA737",
              borderRadius: "8px",
              marginY: "16px",
              marginX: "16px",
            }}
          >
            <Typography
              color={"#333"}
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "center",
                paddingY: "10px",
                cursor: "pointer",
              }}
              component={"div"}
              onClick={() => {
                formRef.current?.requestSubmit();
                // if (resetPass) {
                //   handleResetPassword();
                // } else {
                //   console.log("close modal");
                // }
              }}
            >
              {btnText}
            </Typography>
          </Box>
        </Box>
      )}
    </form>
  );
};

export default MobileSettings;
