import React, { ReactNode, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Typography } from "@mui/material";
import Icons from "../Icons";
import BugReportDetails from "../BugReport";
import Button from "../Button";
import { reportBug } from "../../services/api/dashboard";
import { getToken } from "../../services/api/login";
import withErrorBoundary from "../../utils/withErrorBoundary";

type ToastState = {
  type: string;
  message: string;
};
interface BugDrawerProps {
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  headerText: string;
  cancel: () => void;
  minimized: () => void;
  bugTitle?: string;
  internetSpeed?: string | null;
  browserInfo?: string;
  bugDescription?: string;
  attachment?: (File | string)[];
  handleBugTitleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBugDescriptionChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  captureScreenshot?: () => void;
  setAttachment?: React.Dispatch<React.SetStateAction<(File | string)[]>>;
  setToast?: React.Dispatch<React.SetStateAction<ToastState | null>>;
  bugRepId?: string;
  selectedSubModule?: {
    id: string;
    module: string;
  };
  selectedType?: string | undefined;
  titleError: boolean;
  descriptionError: boolean;
}

const BugDrawer: React.FC<BugDrawerProps> = ({
  open,
  onClose,
  cancel,
  minimized,
  setToast,
  headerText,
  bugTitle,
  bugDescription,
  selectedSubModule,
  attachment,
  handleBugTitleChange,
  selectedType,
  handleBugDescriptionChange,
  titleError,
  descriptionError,
  captureScreenshot,
  setAttachment,
  bugRepId,
}) => {
  const verifyAccess = getToken();
  const isDisabled =
    bugTitle === "" || bugDescription === "" || titleError || descriptionError;

  const handleBugSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("browserInfo", JSON.stringify(browserInfo) || "");
      formData.append("speed", internetSpeed || "");
      formData.append("bug_title", bugTitle || "");
      formData.append("bug_description", bugDescription || "");
      formData.append("file_id", bugRepId || "");

      attachment &&
        attachment.forEach((file) => {
          if (file instanceof File) {
            formData.append("attachment", file, file.name);
          }
        });

      const token = verifyAccess.token;
      const response = await reportBug(formData, token);
      if (response && response.data) {
        const successMessage = response.data.message;
        setToast &&
          setToast({
            type: "success",
            message: successMessage,
          });
        cancel();
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast &&
          setToast({
            type: "error",
            message: errorMessage,
          });
      }
    } catch (error) {
      console.error("Error submitting bug report:", error);
    }
  };


   const [internetSpeed, setInternetSpeed] = React.useState<string | null>(null);
   const getInternetSpeed = async () => {
    const fileUrl = "public/1mb.pdf"; // A small test file (1MB)
    const fileSizeInBits = 1 * 8 * 1024 * 1024; // 1MB in bits
  
    const startTime = performance.now();
  
    try {
      const response = await fetch(fileUrl, { method: "GET", cache: "no-store" });
      await response.blob(); // Ensure full download
  
      const endTime = performance.now();
      const durationInSeconds = (endTime - startTime) / 1000;
      
      const speedMbps = fileSizeInBits / (durationInSeconds * 1_000_000); // Speed in Mbps
      const speedMBps = (speedMbps / 8).toFixed(2); // Convert to MB/s
  
      setInternetSpeed(`${speedMBps} Mbps`); // Display speed in MB/s
    } catch (error) {
      setInternetSpeed("Unable to detect speed");
    }
  };
      // Fetch the internet speed when the component mounts
            React.useEffect(() => {
              getInternetSpeed();
            }, []);


            const [browserInfo, setBrowserInfo] = useState({
              userAgent: navigator.userAgent,
              fullVersion: "Unknown",
            });
          React.useEffect(() => {
            async function getBrowserVersion() {
              try {
                const nav = navigator as any;
        
                if (nav.userAgentData?.getHighEntropyValues) {
                  // ✅ For Chromium browsers (Chrome, Edge, Opera)
                  const highEntropyValues = await nav.userAgentData.getHighEntropyValues(["uaFullVersion"]);
                  setBrowserInfo({
                    userAgent: navigator.userAgent,
                    fullVersion: highEntropyValues.uaFullVersion,
                  });
                } else {
                  let fullVersion = "Unknown";
        
                  if (navigator.userAgent.includes("Firefox")) {
                    // ✅ Improved regex to capture full Firefox version
                    const match = navigator.userAgent.match(/Firefox\/([\d.]+)/);
                    fullVersion = match ? match[1] : "Unknown";
                  } else if (navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome")) {
                    // ✅ Safari version detection (avoids picking up Chrome-based Safari)
                    const safariMatch = navigator.userAgent.match(/Version\/([\d.]+)/);
                    fullVersion = safariMatch ? safariMatch[1] : "Unknown";
                  } else {
                    // ✅ General fallback for other browsers
                    const match = navigator.userAgent.match(/(firefox|chrome|safari|edge|opr|version)[/\s]?([\d.]+)/i);
                    fullVersion = match ? match[2] : "Unknown";
                  }
        
                  setBrowserInfo({
                    userAgent: navigator.userAgent,
                    fullVersion,
                  });
                }
              } catch (error) {
                console.error("Error fetching browser version:", error);
                setBrowserInfo((prev) => ({ ...prev, fullVersion: "Error fetching version" }));
              }
            }
        
            getBrowserVersion();
          }, []);
          console.log(browserInfo.fullVersion);
  return (
    <Box>
      {(["bottom"] as const).map((anchor) => (
        <Box key={anchor}>
          <Drawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            ModalProps={{
                    BackdropProps: {
                      invisible: true,
                    },
                  }}
            sx={{
              ".MuiDrawer-paper": {
                paddingTop: "12px",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              },
            }}
          >
            <Box
              sx={{ display: "flex", paddingX: "16px", alignItems: "center" }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "21.6px",
                  fontFamily: "PolySans_Neutral",
                  color: "#333",
                }}
              >
                {headerText}
              </Typography>
              <Box
                sx={{ marginLeft: "auto" }}
                component={"div"}
                onClick={minimized}
              >
                <Icons name={"minus"} width={24} height={24} />
              </Box>
            </Box>
            <BugReportDetails
              bugTitle={bugTitle}
              bugDescription={bugDescription}
              internetSpeed={internetSpeed}
              browserInfo={browserInfo}
              attachment={attachment}
              handleBugTitleChange={handleBugTitleChange}
              handleBugDescriptionChange={handleBugDescriptionChange}
              titleError={titleError}
              descriptionError={descriptionError}
              captureScreenshot={captureScreenshot}
              setAttachment={setAttachment}
              selectedSubModule={selectedSubModule}
              selectedType={selectedType}
            />
            <Box
              sx={{
                paddingX: "16px",
                paddingY: "16px",
                borderTop: "1px solid #EFEFEF",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  color={"#333"}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                    height: "44px",
                    cursor: "pointer",
                    paddingX: "42px",
                    border: "1px solid #FFA737",
                    borderRadius: "8px",
                    marginRight: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  component={"div"}
                  onClick={cancel}
                >
                  Cancel
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <Button
                    text="Submit bug report"
                    textStyle={{ color: "#333" }}
                    handleClick={isDisabled ? undefined : handleBugSubmit}
                    disabled={isDisabled}
                  />
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      ))}
    </Box>
  );
};

export default withErrorBoundary(BugDrawer,<>Something went wrong with BugDrawer Component</>);
