import React, { useEffect, useRef, useState } from "react";
import "react-video-seek-slider/styles.css";
import { Box, CircularProgress } from "@mui/material";
import "../Video/Video.css";
// import RecordingTabs from "../Tabs";
import '@vidstack/react/player/styles/base.css';
// import {
//     getAOSRecording,
//     getChapters,
//     videoLogFileSeekTime,
// } from "../../services/api/subject";
// import { getToken } from "../../services/api/login";
// import { usePreviousValue } from "../../store/hooks";
// import { formatDate } from "../../utils/utilfns";
import { MediaPlayer, MediaProvider, Poster, Title, useMediaStore } from '@vidstack/react';
import styles from './player.module.css';
import { VideoLayout } from "../videostack-react/components/layouts/video-layout";
import { useNavigate } from "react-router-dom";
// import moment from "moment";
import withErrorBoundary from "../../utils/withErrorBoundary";

interface AOS_Material_Props {
    aos_id?: string;
    class_id: string;
    title: string;
    topic_id: number;
    fk_subject_id: string;
    selectedTopic?: any;
    selectedModuleTypeId?: any;
    setBugRepId: React.Dispatch<React.SetStateAction<string>>;
    setRecordingData?: React.Dispatch<React.SetStateAction<any[]>>;
    setMarkComplete?: any;
    file_id?: any;
    bugRepId?: any;
    // selectedType?: string | undefined;
    subjectAOSDetails?: any[];
    pageSelected?: any;
    file_url?: string;
    aosRecordingDetails: any;
    thumbnail_url?: any;
    transcript_path?: any;
}
const StudentClassRecording: React.FC<AOS_Material_Props> = ({
    // aos_id,
    // class_id,
    // topic_id,
    // fk_subject_id,
    // selectedTopic,
    // selectedModuleTypeId,
    // setBugRepId,
    title,
    // setRecordingData,
    // setMarkComplete,
    file_url,
    aosRecordingDetails,
    thumbnail_url,
    transcript_path,
    // selectedType,
    // pageSelected,
}) => {
    // const verifyAccess = getToken();
    // const previousValue = usePreviousValue(file_id);
    const queryParams = new URLSearchParams(location.search);

    const playerRef = useRef<any>(null);
    const [files, setFiles] = useState<any>([]);
    const [videDetails, setVideDetails] = useState<{ nextVideo: { title: string, filename: string, file_id: string, subject_id: string, class_rec_id: string, class_id: string, thumnail: string }, prevVideo: { title: string, filename: string, file_id: string, subject_id: string, class_rec_id: string, class_id: string, thumnail: string } } | null>(null);
    // const [videoChapters] = useState<any>([]);
    const [videoSrc, setVideoSrc] = useState<string>(file_url || "");
    // const [bugFileId, setFileRepId] = useState(file_id || "");
    const [thumbVideo, setThumbVideo] = useState("");
    const [currentIndex, setCurrentIndex] = useState<any>(-1);
    const { fullscreen: isFullScreen } = useMediaStore(playerRef);
    const [aspectRatio, setAspectRatio] = useState(9 / 6);
    const [loading, setLoading] = useState(true);
    const [vttSrc, setVttSrc ] = useState<string>(transcript_path || " ");

    const recdId = aosRecordingDetails[0]?.recordings?.classes[0]?.files[0]?.rec_id
    console.log('recdId', recdId)

    useEffect(() => {
        console.log("aosRecordingDetails", aosRecordingDetails)

        function getNextAndPrevious(array: Array<any>, currentIndex: number) {
            const arrayLength = array.length;
            const nextIndex = (currentIndex + 1) % arrayLength;
            const previousIndex = (currentIndex - 1 + arrayLength) % arrayLength;
            return {
                next: nextIndex === currentIndex ? undefined : array[nextIndex],
                previous: previousIndex === currentIndex ? undefined : array[previousIndex]
            };
        }

        if (typeof currentIndex !== "undefined" && aosRecordingDetails && aosRecordingDetails.length > 0) {
            const classId = queryParams.get("classId") || "0";
            const fileId = queryParams.get("fileID") || "0";
            const classIndex = aosRecordingDetails[0].recordings?.classes.findIndex((va: any) => parseInt(va.id) === parseInt(classId))
            const fileIndex = aosRecordingDetails[0].recordings?.classes[classIndex > 0 ? classIndex : 0].files.findIndex((va: any) => parseInt(va.id) === parseInt(fileId))
            const classess = aosRecordingDetails[0].recordings?.classes[classIndex >= 0 ? classIndex : 0]?.files
            setFiles(classess || [])
            console.log("aosRecordingDetails", aosRecordingDetails[0].recordings, classId, classIndex)
            const nextprevData = { ...videDetails }
            const { next, previous } = getNextAndPrevious(classess, currentIndex >= 0 ? currentIndex : fileIndex);
            if (next) {
                const { file_name = "", id, thumbnail_url = "", } = next || {};
                nextprevData.nextVideo = { filename: file_name, file_id: id, title: file_name, subject_id: aosRecordingDetails[0].fk_subject_id, class_id: aosRecordingDetails[0].recordings?.classes[classIndex >= 0 ? classIndex : 0].id, class_rec_id: recdId, thumnail: `${process.env.REACT_APP_CDN}/${thumbnail_url}` }
            }
            if (previous) {
                const { file_name = "", id, thumbnail_url = "" } = previous || {};
                nextprevData.prevVideo = { filename: file_name, file_id: id, title: file_name, subject_id: aosRecordingDetails[0].fk_subject_id, class_id: aosRecordingDetails[0].recordings?.classes[classIndex >= 0 ? classIndex : 0].id, class_rec_id: recdId, thumnail: `${process.env.REACT_APP_CDN}/${thumbnail_url}` }
            }
            console.log("nextprevdata", nextprevData)
            console.log('aosRecordingDetails[0]', aosRecordingDetails[0])
            setVideDetails(nextprevData as any)
        }
    }, [currentIndex, aosRecordingDetails]);

    const navigate = useNavigate();

    const handlePrevNext = (videDetails: any) => {
        console.log("videDetails", videDetails)
        const { filename = "", class_rec_id, class_id, subject_id, file_id } = videDetails || {}
        if (filename !== "") {
            const currentIndex = files.findIndex((va: any) => va.file_name === filename)
            const classId = queryParams.get("classId") || "0";
            const classIndex = aosRecordingDetails[0].recordings?.classes.findIndex((va: any) => parseInt(va.id) === parseInt(classId))
            const prevSubject = aosRecordingDetails[0].recordings.classes[classIndex >= 0 ? classIndex : 0].files[currentIndex] || {};
            console.log("nextSubject", prevSubject);
            const newSearch = new URLSearchParams();
            newSearch.set("sid", subject_id);
            newSearch.set("crecId", class_rec_id);
            newSearch.set("classId", class_id);
            newSearch.set("fileID", file_id);
            // newSearch.set("sid", aosRecordingDetails[0].fk_subject_id);
            // newSearch.set("crecId", aosRecordingDetails[0].fk_class_id);
            // newSearch.set("classId", aosRecordingDetails[0].recordings.classes[classIndex>=0?classIndex:0].id);
            // newSearch.set("fileID", prevSubject.id);
            setCurrentIndex(currentIndex);
            navigate(`/class-recording?${newSearch.toString()}`);
            console.log('class_rec_id', class_rec_id)
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (playerRef.current) {
                const newAspectRatio = 16 / 9; // Default aspect ratio
                setAspectRatio(newAspectRatio);
            }
        };

        handleResize(); // Set initial aspect ratio

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);

        };
    }, []);

    useEffect(() => {
        if (file_url) {
            setVideoSrc(file_url)
            setThumbVideo(thumbnail_url)
            setVttSrc(transcript_path)
            setLoading(false);

        }
        setLoading(true);
    }, [file_url]);


    console.log('videDetails', videDetails)
    console.log('aosRecordingSet', aosRecordingDetails[0]?.recordings?.classes[0])
    console.log('transcript_path', transcript_path)
    console.log('thumbnail_url', thumbnail_url)

    return (
        <Box>
            <Box
                id="videoSec3"
                sx={{ display: { md: "block", sm: "block", xs: "block" } }}
            >
                <Box
                    sx={{
                        position: "relative !important",
                        borderRadius: !isFullScreen ? "17px" : "0px",
                    }}
                >
                    <MediaPlayer
                        title={title}
                        src={`${process.env.REACT_APP_CDN}/${videoSrc}`}
                        ref={playerRef}
                        className={styles.player}
                        crossOrigin
                        style={{ background: !isFullScreen ? "#000" : "#2B3258", position: "relative" }}
                        playsInline
                        onCanPlay={() => setLoading(false)}
                    >
                        {loading &&
                            <Box sx={{ display: 'flex', justifyContent: "center", position: "absolute", zIndex: "100", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <CircularProgress size={80} color="inherit" />
                            </Box>
                        }
                        {isFullScreen && <Title style={{ position: "absolute", margin: "30px", top: "10px", fontFamily: "Inter", fontWeight: "600", fontSize: "16px", lineHeight: "24px" }} />}
                        <MediaProvider style={{ height: !isFullScreen ? "525px" : "calc(100vh - 200px)", aspectRatio: aspectRatio, background: !isFullScreen ? "#000" : "#2B3258" }}>
                            <Poster
                                className={styles.poster}
                                src={`${process.env.REACT_APP_CDN}/${thumbVideo}`}
                                crossOrigin={'anonymous'}
                                alt="poster"
                            />

                        </MediaProvider>
                        {videDetails !== null &&
                            <VideoLayout
                                nextprevvideDetails={videDetails}
                                playNextVideo={() => handlePrevNext(videDetails.nextVideo)}
                                playPrevVideo={() => handlePrevNext(videDetails.prevVideo)}
                                thumbnails={`${process.env.REACT_APP_CDN}/${vttSrc}`} />}
                    </MediaPlayer>
                </Box>
            </Box>
            {/* <Box sx={{ display: { md: "block", sm: "block", xs: "block" } }}>
                <RecordingTabs
                    seekToVideo={(time: number) => handleSeekTime(time)}
                    chapters={videoChapters}
                    setBugRepId={setBugRepId}
                    setFileRepId={setFileRepId}
                    aosRelatedDetails={[]}
                    chaptersRecordings={aosRecordingDetails}
                    onVideoClick={(value) => setVideoSrc(value)}
                    aos_id={class_id}
                    subject_id={fk_subject_id}
                    // selectedTopic={selectedTopic}
                    // selectedModuleTypeId={selectedModuleTypeId}
                    setRecordingData={setRecordingData}
                    setMarkComplete={setMarkComplete}
                    file_id={file_id}
                    videoid={bugFileId}
                    setThumbVideo={setThumbVideo}
                    setVttSrc={setVttSrc}
                    selectedPage={pageSelected}
                />
            </Box> */}
        </Box>
    );
};

export default withErrorBoundary(StudentClassRecording, <>Something went wrong with StudentVideoV1 Component</>);
